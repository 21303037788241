<script>
export default {
	methods: {
		getImage(image) {
			return `${require(`@/assets/images/components/LOADING/${image}`)}`;
		},
	},
};
</script>

<template>
	<div
		class="modal bounce absolute flex flex-col items-center justify-center w-full h-full p-3 z-40 right-0 bottom-0"
	>
		<img
			class="image -mb-5 w-28 relative z-10 object-contain"
			:src="getImage('votes_icon.png')"
			alt="Corazón en Mano"
		/>
		<div class="text flex flex-col items-center justify-center text-white text-justify p6 rounded-3xl">
			<span class="text-lg mb-3">Cargando Información...</span>
			<font-awesome-icon icon="fa-solid fa-spinner" class="fa-spin text-4xl" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
/* we will explain what these classes do next! */
@keyframes bounce {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.bounce {
	transition: transform 0.3s;
	animation: bounce 300ms;
}

.modal {
	background-color: rgba($color: #4a6c85, $alpha: 0.5);
}

.secondary-button {
	&:hover {
		animation: none;
	}
}

.image,.text{
	transform: translateY(-50%);
}

.text {
	width: 300px;
	height: 120px;
	background: radial-gradient(
		circle at 100% 0%,
		#00b771,
		#049275 65%,
		#005c52
	);
}
</style>
