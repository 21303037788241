<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import SecondaryButton from "@/components/SecondaryButton.vue";

const _tourData = {
	userExample: {
		id: 8888888888888888,
		code: "1041738",
		name: "MARÍA PEREZ",
		first_name: null,
		photo: null,
		last_name: null,
		email: null,
		is_active: true,
		is_full_time: true,
		work_position_id: 10058,
		work_position: "ASESOR DE SERVICIOS FINANCIEROS",
		agency_id: 321,
		region_id: 296,
		territory_id: 26,
		role_id: 2,
		agency: "DAZ LA PAZ",
		region: "REGION RU LA PAZ",
		territory: "TERRITORIO RU BAJA CALIFORNIA SUR",
		votes: 0,
		emitted_votes: 0,
		voted: false,
		tour_completed: null,
	},
	userExample2: {
		id: 7777777777777777,
		code: "1041738",
		name: "JOSÉ LOPEZ",
		first_name: null,
		photo: null,
		last_name: null,
		email: null,
		is_active: true,
		is_full_time: true,
		work_position_id: 10058,
		work_position: "ASESOR DE SERVICIOS FINANCIEROS",
		agency_id: 321,
		region_id: 296,
		territory_id: 26,
		role_id: 2,
		agency: "DAZ LA PAZ",
		region: "REGION RU LA PAZ",
		territory: "TERRITORIO RU BAJA CALIFORNIA SUR",
		votes: 0,
		emitted_votes: 0,
		voted: false,
		tour_completed: null,
	},
	cultureExample: {
		active: true,
		name: "Liderazgo constructivo",
		answer: "Genera espacios de comunicación donde todos nos sentimos en confianza.",
		created_at: "2022-03-14T17:19:35",
		feedback:
			"Continua buscando soluciones y enfócate en el éxito de todos los colaboradores.",
		id: 7777777777777777,
		deleted: false,
		updated_at: "2022-04-01T03:30:29",
	},
	notificationExample: {
		id: 7777777777777777,
		receiver_id: 9,
		content:
			"Gracias David Higuera te reconozco como Creador/a de Momentos Felices Eres un protagonista de nuestra cultura. Tu orgullo y pasión por la RÚ se nota, y eso habla bien de ti y de nosotros.",
		created_at: "2022-04-06T15:39:01",
		emitter_id: 1,
		title: "Lopez Jesus te ha reconocido.",
		is_unread: true,
		updated_at: "2022-04-06T15:40:03",
		photo: null,
	},
};

export default {
	components: {
		BaseLoading,
		SecondaryButton,
	},
	data() {
		return {
			isTour: false,
			steps: [
				{
					target: "#empezar",
					content: `¡Bienvenido! Aquí inicia tu experiencia.`,
					before: (type) =>
						new Promise(async (resolve) => {
							if (type == "previous")
								await this.$router.push({ name: "Inicio" });
							this.loadStep(-1);
							resolve(true);
						}),
				},
				{
					target: "#search",
					content: `Encuentra a tus compañeros que quieras reconocer.`,
					before: () =>
						new Promise(async (resolve) => {
							await this.$router.push({ name: "Reconocer" });
							await this.$refs.view?.users.push(
								_tourData.userExample
							);
							await this.$refs.view?.users.push(
								_tourData.userExample2
							);
							this.loadStep(1);
							resolve(true);
						}),
				},
				{
					target: "#user_0",
					content: `Escribe su nombre, comenzando por apellidos o número de colaborador y selecciónalo.`,
					before: () =>
						new Promise(async (resolve) => {
							this.loadStep(2);
							resolve(true);
						}),
				},
				{
					target: "#user_0",
					content: `Si está en verde, ya lo reconociste. Espera otra semana. Mientras tanto, reconoce a otros compañeros.`,
					before: () =>
						new Promise(async (resolve) => {
							await this.$refs.view?.fakeVote();
							this.loadStep(3);
							resolve(true);
						}),
				},
				{
					target: "#culture_0",
					content: `Selecciona la frase que mejor describa a tu creador de momentos felices.`,
					before: () =>
						new Promise(async (resolve) => {
							await this.$refs.view?.selectUser(
								_tourData.userExample2
							);
							this.loadStep(4);
							resolve(true);
						}),
				},
				{
					target: "#culture_1",
					content: `Solo podrás seleccionar una cualidad.`,
					before: () =>
						new Promise(async (resolve) => {
							await this.$refs.view?.selectCulture(
								this.$refs.view?.cultures[1]
							);
							this.loadStep(5);
							resolve(true);
						}),
				},
				{
					target: "#buttonReconocer",
					content: `¡Envía tu reconocimiento!`,
					before: () =>
						new Promise(async (resolve) => {
							this.loadStep(6);
							resolve(true);
						}),
				},
				{
					target: "#goBack",
					content: `¡Ups! No es a quien querías reconocer, regresa al Buscador. `,
					before: () =>
						new Promise(async (resolve) => {
							this.loadStep(7);
							resolve(true);
						}),
				},
				{
					target: "#Notificaciones",
					content: `Tú también eres un creador de momentos felices! Conoce quién te han reconocido.`,
					before: () =>
						new Promise(async (resolve) => {
							await this.$router.push({ name: "Notificaciones" });
							this.loadStep(8);
							resolve(true);
						}),
				},
				{
					target: "#notification_0",
					content: `Haz clic en cada notificación para saber más.`,
					before: () =>
						new Promise(async (resolve) => {
							await this.$refs.view?.messages.unshift(
								_tourData.notificationExample
							);
							this.loadStep(9);
							resolve(true);
						}),
				},
				{
					target: "#Usuario",
					content: `Conoce los principios que más vives y en los que debes de trabajar un poco más.`,
					before: () =>
						new Promise(async (resolve) => {
							await this.$router.push({ name: "Usuario" });
							this.loadStep(10);
							resolve(true);
						}),
				},
			],
			myOptions: {
				labels: {
					buttonSkip: "Saltar guía",
					buttonNext: "Siguiente",
					buttonStop: "Comenzar",
				},
				enabledButtons: { buttonPrevious: false },
			},
			myCallbacks: {
				onFinish: this.finishTour,
				onSkip: this.finishTour,
				onStop: this.finishTour,
			},
			userInputs: [
				{ name: "Nombre(s)", value: null, field: "first_name" },
				{ name: "Apellidos", value: null, field: "last_name" },
			],
			currentInput: 0,
			isUpdated: false,
		};
	},
	created() {
		//turn It false if you want to show Modal Form
		this.isUpdated = true;
		this.getProfile();
	},
	mounted() {
		//use on Modal form
		// if (!this.readUser.tour_completed && this.nameUpdated) {
		if (!this.readUser.tour_completed) {
			this.loadTour();
		}
	},
	updated() {
		//use on Modal form
		// if (this.$route.params.tour === "help" && this.nameUpdated) {
		if (this.$route.params.tour === "help") {
			this.loadTour();
		}
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			readUser: "auth/readUser",
			nameUpdated: "auth/readUpdated",
		}),
	},
	methods: {
		...mapMutations({
			setNewName: "updateName/SET_NEW_NAME",
		}),
		...mapActions({
			updateName: "updateName/updateName",
			getProfile: "auth/getUser",
			setTourComplete: "tourComplete/setTourComplete",
		}),
		loadTour() {
			this.$route.params.tour = "";
			this.$tours["recorridoGuiado"].start();
			this.isTour = true;
		},
		finishTour() {
			this.stopTour();
			if (this.$route.name != "Inicio")
				this.$router.push({ name: "Inicio" });
		},
		stopTour() {
			const screen = this.$el.querySelector("#screen");
			if (screen) screen.remove();
			this.setTourComplete();
			this.isTour = false;
		},
		loadStep(currentStep) {
			const step = currentStep > -1 ? currentStep : 0;

			const element = this.$el.querySelector(this.steps[step].target);
			const screen = this.$el.querySelector("#screen");
			if (screen) screen.remove();
			const prevStep = this.$el.querySelector(".tour-focus") || null;
			if (prevStep) prevStep.classList.remove("tour-focus");

			if (element) {
				element.classList.add("tour-focus");
				element.insertAdjacentHTML(
					"afterend",
					`<div id="screen" class="dark-screen"></div>`
				);
			}
		},
		nextInput() {
			this.currentInput < this.userInputs.length - 1
				? this.currentInput++
				: this.submit();
		},
		submit() {
			if (
				this.userInputs[0].value.length > 0 &&
				this.userInputs[1].value.length > 0
			) {
				let payload = {};
				for (let input in this.userInputs) {
					payload[this.userInputs[input].field] =
						this.userInputs[input].value;
				}

				//TEST
				payload["basic_info"] = false;
				this.setNewName(payload);
				this.updateName()
					.then((response) => {
						if (response) {
							this.getProfile();
						}
					})
					.finally(() => {
						this.isUpdated = true;
						if (!this.readUser.tour_completed) {
							this.loadTour();
						}
					});
			}
		},
	},
};
</script>
<template>
	<div class="votacion bg-gray">
		<div class="layout home justify-end max-w-sm m-auto">
			<router-view
				class="flex-grow"
				ref="view"
				:isTour="isTour"
			></router-view>
			<BaseLoading v-if="isLoading && !isTour"></BaseLoading>
			<v-tour
				class="flex-none"
				name="recorridoGuiado"
				:steps="steps"
				:options="myOptions"
				:callbacks="myCallbacks"
			>
			</v-tour>
			<form
				v-if="!nameUpdated && !isUpdated"
				class="dark-screen flex flex-col items-center justify-center"
			>
				<label class="text-white text-lg w-5/6 text-center mb-6"
					>Antes de comenzar, ayúdanos a mantener tus datos
					actualizados:</label
				>
				<input
					v-for="(input, key) in userInputs"
					:key="key"
					class="name w-52 mb-4 text-lg black pl-4 pr-8 py-2 rounded-md bg-white go-in"
					type="text"
					:placeholder="input.name"
					v-model="input.value"
				/>
				<secondary-button
					v-if="userInputs[0].value && userInputs[1].value"
					:class="{
						'grow-up': userInputs[0].value && userInputs[1].value,
					}"
					@click.native="submit"
					text="Continuar"
				></secondary-button>
			</form>
		</div>
	</div>
</template>
<style lang="scss">
//ANIMATIONS
@keyframes go_in {
	0% {
		transform: translateX(100vw);
		height: 0;
	}
	20% {
		transform: translateX(20vw);
	}
	100% {
		transform: translateX(0vw);
		height: 3rem;
	}
}
@keyframes grow_up {
	0% {
		height: 0;
	}
	100% {
		height: 3rem;
	}
}

.grow-up {
	animation: grow_up 0.4s cubic-bezier(0.1, 0.1, 0.1, 1);
}
.go-in {
	animation: go_in 1s cubic-bezier(0.1, 0.1, 0.1, 1);
}

//STYLES
.layout {
	height: 100vh;
}

.last_name {
	transform: translateX(-100vw);
}

.votacion {
	display: flex;
	justify-content: center;
	height: 100vh;
}

.home {
	&.layout {
		max-height: 844px;
		min-height: unset;
		overflow: hidden;
	}

	.overlay {
		border-radius: 30px;
		width: calc(100% - 1rem);
	}
}

.dark-screen {
	position: fixed;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	background: rgba($color: #000000, $alpha: 0.7);
	z-index: 15;
}

.tour-focus {
	position: relative;
	z-index: 20;
}

.v-tour {
	.v-step {
		padding: 20px 10px;
		border-radius: 6px;
		@extend .gradient-green;
		color: #fff;
	}

	.v-step__button {
		@extend .gradient-button-gray;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: auto;
		padding: 0.5rem 1rem;
		border-radius: 15px;
		box-shadow: 0 16px 12px rgba($color: #224c57, $alpha: 0.25);
		border: get-vh(4px) solid #f2f6ff;
		color: $blue;
		@media (min-width: 768px) {
			&:hover {
				animation: bounce-in 0.5s;
			}
		}
	}
}
</style>
